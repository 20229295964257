


























import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import ChangePassword from '@/components/profile/ChangePassword.vue';
import EditProfileData from '@/components/profile/EditProfileData.vue';

const AuthStore = namespace('AuthStore');

@Component({
  name: 'Profile',
  components: {EditProfileData, ChangePassword},
})
export default class Profile extends Vue {

}
