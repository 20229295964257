
















































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Form} from '@/types';
import {User} from '@/models/User';

const AuthStore = namespace('AuthStore');

@Component({
  name: 'EditProfileData',
})
export default class EditProfileData extends Vue {

  public $refs!: {
    form: Form,
    cpfFormItem: Form,
  };

  @AuthStore.State
  public user!: User;

  public loading: boolean = false;
  public bloqueiaCpf: boolean = false;

  public model: any = {
    cpf: '',
    name: '',
    login: '',
    email: '',
    fone: '',
  };

  public rules: any = {
    name: [
      {
        required: true,
        message: 'Nome de usuário é obrigatório',
        trigger: 'blur',
      },
      {
        min: 3,
        message: 'O nome comleto deve ter no mínimo 3 caracteres',
        trigger: 'blur',
      },
    ],
    email: [
      {
        required: true,
        message: 'O e-mail é obrigatório',
        trigger: 'blur',
      },
      {
        type: 'email',
        message: 'O formato do e-mail não é válido',
        trigger: 'blur',
      },
    ],
    login: [
      {
        required: true,
        message: 'Nome de usuário é obrigatório',
        trigger: 'blur',
      },
      {
        min: 4,
        message: 'O nome de usuário deve ter no mínimo 4 caracteres',
        trigger: 'blur',
      },
    ],
  };

  public async submit() {
    await this.$refs.form.validate((valid) => {
      if (!valid) {
        return false;
      }
      this.loading = true;
      const {name, fone} = this.model;
      this.$axios
        .patch(`/usuario/${this.user.login}`, {name, fone})
        .then((response) => {
          this.$message.success('Dados atualizados com sucesso');
          if (this.model.cpf) {
            this.bloqueiaCpf = true;
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.$message.error(error.response.data);
          } else {
            this.$message.error('Erro inesperado ao tentar trocar a senha para o usuário logado!');
          }
        })
        .finally(() => this.loading = false);
    });
  }

  public clean(): void {
  }

  public created(): void {
    this.$axios
        .get(`/usuario/${this.user.login}`)
        .catch((error) => {
          this.$message.error('Não foi possível recuperar as informações do usuário logado.');
        })
        .then((response) => {
          if (response) {
            this.model = response.data;
            if (this.model.cpf) {
              this.bloqueiaCpf = true;
            } else {
              this.bloqueiaCpf = false;
            }
          }
        });
  }
}
