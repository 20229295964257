


































import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {Form} from '@/types';
import {User} from '@/models/User';

const AuthStore = namespace('AuthStore');

@Component({
  name: 'ChangePassword',
})
export default class ChangePassword extends Vue {

  public $refs!: {
    form: Form,
  };

  @AuthStore.State
  public user!: User;

  public loading: boolean = false;

  public model: any = {
    login: '',
    password: '',
    confirm: '',
  };

  public rules: any = {
    password: [
      {
        required: true,
        message: 'Senha é obrigatório',
        trigger: 'blur',
      },
      {
        min: 6,
        message: 'A senha deve ter no mínimo 6 caracteres',
        trigger: 'blur',
      },
      {
        validator: (rule, value, callback) => {
          if (!/[A-Z]/.test(value)) {
            callback(new Error('A senha deve conter pelo menos um Caractere Maiúsculo'));
          }
          if (!/\d/.test(value)) {
            callback(new Error('A senha deve conter pelo menos um Número'));
          }
          if (!/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value)) {
            callback(new Error('A senha deve conter pelo menos um Caractere Especial'));
          }
          callback();
        },
        trigger: 'blur',
      },
    ],
    confirm: [
      {
        required: true,
        message: 'Confirmar a senha é obrigatório',
        trigger: 'blur',
      },
      {
        validator: (rule, value, callback) => {
          if (value === this.model.password) {
            callback();
          } else {
            callback(new Error('Os valores informados não são correspondentes.'));
          }
        },
        trigger: 'blur',
      },
    ],
  };

  public async onChangePassword() {
    await this.$refs.form.validate((valid) => {
      if (!valid) {
        return;
      }
      this.loading = true;
      this.model.login = this.user.login;
      this.$axios
          .post('/auth/request-pass', this.model)
          .then((response) => {
            this.$message.success('Senha alterada com sucesso. Um e-mail com a nova senha foi enviado para você não esquecer!');
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.$message.error('O email informado não possui uma conta cadastrada!');
            } else {
              this.$message.error('Erro inesperado ao tentar trocar a senha para o usuário logado!');
            }
          })
          .finally(() => this.loading = false);
    });
  }
}
